import React, { useState } from 'react';
import "./add.scss";
import { apiKey } from '../../config/config';

const Add = ({ setOpen = () => {}, slug = "", columns = [], postUrl = "" }) => {
  // Initialize state for each form field dynamically
  const [formData, setFormData] = useState(
    columns.reduce((acc, column) => {
      if (column.field !== "id" && column.field !== "img") {
        acc[column.field] = '';
      }
      return acc;
    }, {})
  );

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Submit form data to the backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "X-API-Key": apiKey, // Add the API key to the headers
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        //alert(`${slug.charAt(0).toUpperCase() + slug.slice(1)} created successfully`);  Long term build this into better alert
        setOpen(false); // Close the modal
      } else {
        alert(`Error creating ${slug}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Network error');
    }
  };

  return (
    <div className="add">
      <div className="modal">
        <span className="close" onClick={() => setOpen(false)}>
          X
        </span>
        <h1>Add new {slug}</h1>
        <form onSubmit={handleSubmit}>
          {columns
            .filter((item) => item.field !== "id" && item.field !== "img")
            .map((column, index) => (
              <div className="item" key={index}>
                <label>{column.headerName}</label>
                <input
                  type={column.type || "text"}
                  name={column.field}
                  placeholder={column.field}
                  value={formData[column.field] || ""}
                  onChange={handleChange}
                />
              </div>
            ))}
          <button>Send</button>
        </form>
      </div>
    </div>
  );
};

export default Add;
