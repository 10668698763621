import './styles/global.scss';
import Home from "./pages/home/home.js";
import Users from "./pages/users/users.js";
import User from "./pages/user/user.js";
import Products from "./pages/products/products.js";
import Tasks from "./pages/tasks/tasks.js";
import Task from "./pages/task/task.js";
import Navbar from "./components/navbar/navbar.jsx";
import Footer from "./components/footer/footer.jsx";
import Menu from "./components/menu/menu.jsx";
import Login from "./pages/login/Login.js";
import Messages from "./pages/messages/messages.js";
import Agent from "./pages/agent/agent.js";
import Services from "./pages/services/services.js";
import Ideas from "./pages/ideas/ideas.js";
import Notes from "./pages/notes/notes.js"
import Note from "./pages/note/note.js"
import Message from "./pages/message/message.js"
import MessageNew from "./pages/messagenew/messagenew.js"
import NoteNew from "./pages/notenew/notenew.js"
import CalendarPage from "./pages/calendar/calendar.js"
import EventPage from "./pages/eventpage/eventpage.js"
import DocsPage from "./pages/documentspage/documentspage.js"
import ProtectedRoute from './components/protectedroute/protectedroute';

//import io from 'socket.io-client';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

//const logger = require('./logger');

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const handleLogin = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const Layout = () => {
    return (
      <div className="main">
        <Navbar onLogout={handleLogout} />
        <div className="container">
          <div className="menuContainer">
            <Menu />
          </div>
          <div className="contentContainer">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: <ProtectedRoute element={<Layout />} token={token} />,
      children: [
        {
          path: '/',
          element: <ProtectedRoute element={<Home />} token={token} />,
        },
        {
          path: 'users',
          element: <ProtectedRoute element={<Users />} token={token} />,
        },
        {
          path: '/users/:id',
          element: <ProtectedRoute element={<User />} token={token} />,
        },
        {
          path: 'products',
          element: <ProtectedRoute element={<Products />} token={token} />,
        },
        {
          path: 'note-new',
          element: <ProtectedRoute element={<NoteNew />} token={token} />,
        },
        {
          path: 'notes',
          element: <ProtectedRoute element={<Notes />} token={token} />,
        },
        {
          path: '/notes/:id',
          element: <ProtectedRoute element={<Note />} token={token} />,
        },
        {
          path: 'ideas',
          element: <ProtectedRoute element={<Ideas />} token={token} />,
        },
        {
          path: 'tasks',
          element: <ProtectedRoute element={<Tasks />} token={token} />,
        },
        {
          path: '/task/:id',
          element: <ProtectedRoute element={<Task />} token={token} />,
        },
        {
          path: 'messages',
          element: <ProtectedRoute element={<Messages />} token={token} />,
        },
        {
          path: 'messages-new',
          element: <ProtectedRoute element={<MessageNew />} token={token} />,
        },
        {
          path: '/messages/:id',
          element: <ProtectedRoute element={<Message />} token={token} />,
        },
        {
          path: 'agent',
          element: <ProtectedRoute element={<Agent />} token={token} />,
        },
        {
          path: 'services',
          element: <ProtectedRoute element={<Services />} token={token} />,
        },
        {
          path: 'calendar',
          element: <ProtectedRoute element={<CalendarPage />} token={token} />,
        },
        {
          path: 'event/:id',
          element: <ProtectedRoute element={<EventPage />} token={token} />,
        },
        {
          path: 'docs',
          element: <ProtectedRoute element={<DocsPage />} token={token} />,
        },
      ],
    },
    {
      path: '/login',
      element: <Login onLogin={handleLogin} />,
    },
    {
      path: '*', // Catch-all route for undefined paths
      element: <Navigate to="/login" />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;