import React, { useState, useEffect } from "react";
import DataTable from "../../components/dataTable/DataTable";
import Add from "../../components/add/add";
import "./documentspage.scss";
import { backendURL, apiKey } from '../../config/config';

const columns = [
  { field: "my_document_name", headerName: "Title", width: 400 },
  {
    field: "document_url",
    headerName: "URL",
    width: 150,
    renderCell: (params) => (
      <a href={params.value} target="_blank" rel="noopener noreferrer">
        link
      </a>
    ),
  },
];

const DocsPage = () => {
  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendURL}/docs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": apiKey, // Add the API key to the headers
          }});
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const docsWithUniqueId = data.docDetails.map(doc => ({
          ...doc,
          id: doc.document_id // Use task_id as the unique identifier
        }));
        setTasks(docsWithUniqueId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [open]); // Add `open` to the dependencies to refetch tasks when the modal is closed

  return (
    <div className="documentpage">
      <div className="info">
        <h1>Documents</h1>
        <button onClick={() => setOpen(true)}>Add New Task</button>
      </div>
      <DataTable
        columns={columns}
        rows={tasks}
        slug="tasks"
        backendUrl={`${backendURL}/docs`}
      />
      {open && (
        <Add
          slug="tasks"
          columns={columns}
          setOpen={setOpen}
          postUrl={`${backendURL}/docs`}
        />
      )}
    </div>
  );
};

export default DocsPage;
