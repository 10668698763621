import NoteBox from "../../components/notebox/notebox"
import "./note.scss"

const Note = () => {

  //Fetch data and send to Single Component
  
  return (
    <div className="note">
      <NoteBox />
    </div>
  )
}

export default Note