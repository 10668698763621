import React from 'react';
import "./home.scss"

const Home = () => {
    return (
    <div className="home">
        <div className="box box1">Box1</div>
        <div className="box box2">Box2</div>
        <div className="box box3">Box3</div>
        <div className="box box4">box4</div>
        <div className="box box5">box5</div>
        <div className="box box6">Box6</div>
        <div className="box box7">box7</div>
        <div className="box box8">Box8</div>
        <div className="box box9">Box9</div>
    </div>
    )
}
export default Home;