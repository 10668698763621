import Serviceslist from "../../components/serviceslist/Serviceslist"
import "./services.scss"

const Services = () => {

  //Fetch data and send to Single Component
  
  return (
    <div className="services">
      <Serviceslist />
    </div>
  )
}

export default Services