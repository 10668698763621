import React from 'react';
import "./agent.scss";
import io from 'socket.io-client';
import { backendURL, apiKey } from '../../config/config';

// Message component
const Message = ({ text, type, isTypingIndicator }) => {
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div className={`message ${type}`}>
      {isTypingIndicator ? (
        <div className="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : type === 'chat-agent' ? (
        <div dangerouslySetInnerHTML={createMarkup(text)} />
      ) : (
        <div>{text}</div>
      )}
    </div>
  );
};

// Chat component
class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      userInput: '',
      tempMessage: '',
      isStreaming: false,
      sentenceBuffer: '',
      isAudioEnabled: true
    };

    this.messagesEndRef = React.createRef();

    this.handleMessageSend = this.handleMessageSend.bind(this);
    this.handleTextToSpeech = this.handleTextToSpeech.bind(this);
    this.toggleAudio = this.toggleAudio.bind(this);

    console.log("Attempting to connect to Socket.IO server...");
    this.socket = io(backendURL, {
      auth: {
        apiKey: apiKey
      }
    });

    this.socket.on('connect', () => {
      console.log('Successfully connected to Socket.IO server');
    });

    this.socket.on('connect_error', (error) => {
      console.error('Connection error:', error.message);
    });

    this.socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected from Socket.IO server:', reason);
    });

    this.socket.on('response', (data) => {
      console.log("Received response from server:", data);
      this.setState(prevState => {
        const newStream = prevState.tempMessage + data;
        console.log("Updated tempMessage:", newStream);

        const updatedMessages = [...prevState.messages];
        if (prevState.isStreaming) {
          updatedMessages[updatedMessages.length - 1].text = newStream;
          updatedMessages[updatedMessages.length - 1].isTypingIndicator = false;
        } else {
          updatedMessages.push({ text: newStream, type: 'chat-agent', isTypingIndicator: false });
        }

        const sentences = (prevState.sentenceBuffer + data).split(/([.!?])\s+/);
        const completeSentences = sentences.slice(0, -1).join('');
        const newBuffer = sentences[sentences.length - 1];

        if (completeSentences && this.state.isAudioEnabled) {
          this.handleTextToSpeech(completeSentences);
        }

        return { 
          tempMessage: newStream,
          messages: updatedMessages,
          isStreaming: true,
          sentenceBuffer: newBuffer
        };
      }, this.scrollToBottom);
    });

    this.socket.on('response_end', () => {
      console.log("End of response stream");
      if (this.state.sentenceBuffer && this.state.isAudioEnabled) {
        this.handleTextToSpeech(this.state.sentenceBuffer);
      }
      this.setState(prevState => {
        return {
          tempMessage: '',
          isStreaming: false,
          sentenceBuffer: ''
        };
      }, this.scrollToBottom);
    });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleMessageSend() {
    console.log("Attempting to send message...");
    const { userInput } = this.state;
    if (userInput.trim() !== '') {
      console.log("Sending request to server:", userInput);
      this.socket.emit('request', userInput);
      this.setState(prevState => {
        const newMessages = [
          ...prevState.messages, 
          { text: userInput, type: 'chat-user', isTypingIndicator: false },
          { text: '', type: 'chat-agent', isTypingIndicator: true }
        ];
        console.log("Updated messages list after sending user message:", newMessages);
        return {
          messages: newMessages,
          userInput: '',
          tempMessage: '',
          isStreaming: true,
          sentenceBuffer: ''
        };
      }, this.scrollToBottom);
    } else {
      console.log("User input is empty, not sending request.");
    }
  }

  handleInputChange = event => {
    this.setState({ userInput: event.target.value });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleMessageSend();
    }
  };

  handleTextToSpeech(text) {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(utterance);
    } else {
      console.error("Text-to-speech not supported in this browser.");
    }
  }

  toggleAudio() {
    this.setState(prevState => ({
      isAudioEnabled: !prevState.isAudioEnabled
    }));
  }

  render() {
    const { messages, userInput, isAudioEnabled } = this.state;

    return (
      <div className="chat-container">
        <div className="chat-box">
          {messages.map((message, index) => {
            console.log("Rendering message:", message);
            return <Message key={index} text={message.text} type={message.type} isTypingIndicator={message.isTypingIndicator} />;
          })}
          <div ref={this.messagesEndRef} />
        </div>
        <div className="input-box">
          <input
            type="text"
            placeholder="Type your message here..."
            value={userInput}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
          <button onClick={this.handleMessageSend}>Send</button>
          <button onClick={this.toggleAudio}>
            {isAudioEnabled ? 'Disable Audio' : 'Enable Audio'}
          </button>
        </div>
      </div>
    );
  }
}

export default Agent;