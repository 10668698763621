import React from 'react';
import "./products.scss"

const Products = () => {
    return (
    <div className="products">
        <div className="info">
        <h1>Products</h1>
      </div>
    </div>
    )
}
export default Products;