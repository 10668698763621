import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { backendURL, apiKey } from '../../config/config';

import "./messagebox.scss";

const MessageBox = () => {
  const { id } = useParams();
  const [messageBody, setMessageBody] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchMessage = async () => {
          try {
              const response = await axios.get(`${backendURL}/messages/${id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "X-API-Key": apiKey, // Add the API key to the headers
                }});
              const messageDetails = response.data.messageDetails[0];
              setMessageBody(messageDetails.message_body);
          } catch (error) {
              setError('Error fetching message');
              console.error('Error fetching message:', error);
          }
      };

      fetchMessage();
  }, [id]);

  if (error) {
      return <div>{error}</div>;
  }

  return (
      <div>
          <h1>Message Body</h1>
          <div className="message-container">
          <div dangerouslySetInnerHTML={{ __html: messageBody }} />
          </div>
      </div>
  );
};

export default MessageBox;
