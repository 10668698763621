import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './eventpage.scss';
import { backendURL } from '../../config/config';

const EventPage = () => {
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    fetch(`${backendURL}/calendar/${id}`)
      .then(response => response.json())
      .then(data => {
        if (data.eventDetails && data.eventDetails.length > 0) {
          setEventDetails(data.eventDetails[0]);
        }
      })
      .catch(error => console.error('Error fetching event details:', error));
  }, [id]);

  if (!eventDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="event-details">
      <h1>{eventDetails.title}</h1>
      <p><strong>Calendar:</strong> {eventDetails.calendar_display_name}</p>
      <p><strong>Description:</strong></p>
      <div dangerouslySetInnerHTML={{ __html: eventDetails.description }} />
      {eventDetails.video_uri && (
        <p><strong>Video Link:</strong> <a href={eventDetails.video_uri} target="_blank" rel="noopener noreferrer">{eventDetails.video_uri}</a></p>
      )}
      <p><strong>Event ID:</strong> {eventDetails.event_id}</p>
      <p><strong>External Event ID:</strong> {eventDetails.external_event_id}</p>
      <p><strong>External ETag:</strong> {eventDetails.external_etag}</p>
      <p><strong>Type:</strong> {eventDetails.type}</p>
      <p><strong>Visibility:</strong> {eventDetails.visibility}</p>
      <p><strong>Transparency:</strong> {eventDetails.transparency}</p>
      <p><strong>Recurring Event ID:</strong> {eventDetails.recurring_event_id}</p>
      <p><strong>Start Time:</strong> {new Date(eventDetails.start_time).toLocaleString()}</p>
      <p><strong>Time Zone:</strong> {eventDetails.time_zone}</p>
      <p><strong>End Time:</strong> {new Date(eventDetails.end_time).toLocaleString()}</p>
      <p><strong>Status:</strong> {eventDetails.status}</p>
    </div>
  );
};

export default EventPage;
