import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './calendar.scss';
import { backendURL, apiKey } from '../../config/config';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('month'); // 'month', 'week', or 'day'
  const [availableCalendars, setAvailableCalendars] = useState([]);
  const [calendarVisibility, setCalendarVisibility] = useState({});

  useEffect(() => {
    fetch(`${backendURL}/calendar/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": apiKey, // Add the API key to the headers
      }})
      .then(response => response.json())
      .then(data => {
        setEvents(data.eventDetails);
        const calendars = Array.from(new Set(data.eventDetails.map(event => event.calendar)));
        setAvailableCalendars(calendars);
        const visibility = {};
        calendars.forEach(calendar => {
          visibility[calendar] = true;
        });
        setCalendarVisibility(visibility);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  const getMonthName = (date) => date.toLocaleString('default', { month: 'long' });
  const getYear = (date) => date.getFullYear();
  const getWeekStart = (date) => {
    const start = new Date(date);
    start.setDate(date.getDate() - date.getDay());
    return start;
  };

  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const startDayOfWeek = startOfMonth.getDay();
  const daysInMonth = endOfMonth.getDate();

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const changeMonth = (offset) => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + offset, 1);
    setCurrentDate(newDate);
  };

  const changeWeek = (offset) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + offset * 7);
    setCurrentDate(newDate);
  };

  const changeDay = (offset) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + offset);
    setCurrentDate(newDate);
  };

  const parseLocalDateString = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < startDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} className="empty-day"></div>);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const dateString = date.toISOString().split('T')[0];
      const dayEvents = events.filter(event => parseLocalDateString(event.date).toISOString().split('T')[0] === dateString && calendarVisibility[event.calendar]);
      days.push(
        <div key={day} className="day">
          <div className="date" onClick={() => { setCurrentDate(date); setView('day'); }}>
            {day}
          </div>
          {dayEvents.map(event => (
            <Link key={event.id} to={`/event/${event.id}`} className={`event ${event.calendar.toLowerCase().replace(' ', '-')}`}>
              {event.title}
            </Link>
          ))}
        </div>
      );
    }
    return days;
  };

  const renderWeek = () => {
    const weekStart = getWeekStart(currentDate);
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(weekStart);
      day.setDate(weekStart.getDate() + i);
      const dateString = day.toISOString().split('T')[0];
      const dayEvents = events.filter(event => parseLocalDateString(event.date).toISOString().split('T')[0] === dateString && calendarVisibility[event.calendar]);
      days.push(
        <div key={i} className="day">
          <div className="date" onClick={() => { setCurrentDate(day); setView('day'); }}>
            {day.getDate()}
          </div>
          {dayEvents.map(event => (
            <Link key={event.id} to={`/event/${event.id}`} className={`event ${event.calendar.toLowerCase().replace(' ', '-')}`}>
              {event.title}
            </Link>
          ))}
        </div>
      );
    }
    return days;
  };

  const renderDay = () => {
    const dateString = currentDate.toISOString().split('T')[0];
    const dayEvents = events.filter(event => parseLocalDateString(event.date).toISOString().split('T')[0] === dateString && calendarVisibility[event.calendar]);
    return (
      <div className="day-view">
        <div className="date-header">
          <button onClick={() => changeDay(-1)}>{"<"}</button>
          <div>{currentDate.toDateString()}</div>
          <button onClick={() => changeDay(1)}>{">"}</button>
        </div>
        {dayEvents.map(event => (
          <Link key={event.id} to={`/event/${event.id}`} className={`event ${event.calendar.toLowerCase().replace(' ', '-')}`}>
            {event.title}
          </Link>
        ))}
      </div>
    );
  };

  return (
    <div className="calendar">
      <div className="header">
        <button onClick={() => (view === 'month' ? changeMonth(-1) : (view === 'week' ? changeWeek(-1) : changeDay(-1)))}>
          {"<"}
        </button>
        <div>
          {view === 'month' ? `${getMonthName(currentDate)} ${getYear(currentDate)}` : 
           view === 'week' ? `Week of ${getMonthName(getWeekStart(currentDate))} ${getWeekStart(currentDate).getDate()}, ${getYear(currentDate)}` : 
           currentDate.toDateString()}
        </div>
        <button onClick={() => (view === 'month' ? changeMonth(1) : (view === 'week' ? changeWeek(1) : changeDay(1)))}>
          {">"}
        </button>
        <button onClick={() => setView(view === 'month' ? 'week' : (view === 'week' ? 'day' : 'month'))}>
          {view === 'month' ? 'Week View' : (view === 'week' ? 'Day View' : 'Month View')}
        </button>
      </div>
      <div className="filters">
        {availableCalendars.map(calendar => (
          <label key={calendar}>
            <input
              type="checkbox"
              checked={calendarVisibility[calendar]}
              onChange={(e) => setCalendarVisibility({ ...calendarVisibility, [calendar]: e.target.checked })}
            />
            {calendar}
          </label>
        ))}
      </div>
      <div className="days-of-week">
        {daysOfWeek.map((day) => (
          <div key={day} className="day-of-week">{day}</div>
        ))}
      </div>
      <div className="days">
        {view === 'month' ? renderDays() : (view === 'week' ? renderWeek() : renderDay())}
      </div>
    </div>
  );
};

export default Calendar;
