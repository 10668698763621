import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "./dataTable.scss";
import { Link } from "react-router-dom";
import { apiKey } from '../../config/config';

const DataTable = ({ columns, rows, slug, backendUrl }) => {
  // Function to handle task deletion
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${backendUrl}/delete/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          "X-API-Key": apiKey, // Add the API key to the headers
        },
        body: JSON.stringify({
          deleted_at: new Date().toISOString() // ISO string format for DATETIME
        })
      });

      if (response.ok) {
      //  alert("Task marked as deleted");
        // Optionally, you could refetch the rows or remove the row from state here
        window.location.reload(); // Simplest way to refresh data (not ideal but effective)
      } else {
        alert("Failed to mark task as deleted");
      }
    } catch (error) {
      console.error("Error deleting task:", error);
      alert("Network error while deleting task");
    }
  };

  const handleComplete = async (id) => {
    try {
      const response = await fetch(`${backendUrl}/completed/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          "X-API-Key": apiKey, // Add the API key to the headers
        },
        body: JSON.stringify({
          deleted_at: new Date().toISOString() // ISO string format for DATETIME
        })
      });

      if (response.ok) {
      //  alert("Task marked as deleted");
        // Optionally, you could refetch the rows or remove the row from state here
        window.location.reload(); // Simplest way to refresh data (not ideal but effective)
      } else {
        alert("Failed to mark task as deleted");
      }
    } catch (error) {
      console.error("Error deleting task:", error);
      alert("Network error while deleting task");
    }
  };

  const actionColumn = {
    field: "action",
    headerName: "Action",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="action">
          <Link to={`/${slug}/${params.row.id}`}>
            <img src="/post.svg" alt="" />
          </Link>
          <div className="delete" onClick={() => handleDelete(params.row.id)}>
            <img src="/delete.svg" alt="Delete" />
          </div>
          <div className="complete" onClick={() => handleComplete(params.row.id)}>
            <img src="/view.svg" alt="Complete" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="dataTable">
      <DataGrid
        className="dataGrid"
        rows={rows}
        columns={[...columns, actionColumn]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          }
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 }
          }
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        checkboxSelection
        disableRowSelectionOnClick
        //disableColumnFilter
        disableDensitySelector
        disableColumnSelector
      />
    </div>
  );
};

export default DataTable;
