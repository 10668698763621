import MessageBox from "../../components/messagebox/messagebox"
import "./message.scss"

const Message = () => {

  //Fetch data and send to Single Component
  
  return (
    <div className="message">
      <MessageBox />
    </div>
  )
}

export default Message