import React from 'react';
import Calendar from '../../components/calendar/calendar';
import "./calendar.scss"

function CalendarPage() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Simple Calendar</h1>
          <Calendar />
        </header>
      </div>
    );
  }

  export default CalendarPage;