import React from 'react'
import "./footer.scss"

const Footer = () =>{
    return (
        <div className="footer">
            <span>Cockpit</span>
            <span>Copyright Cockpit</span>
        </div>
    )
}

export default Footer