import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backendURL, apiKey } from '../../config/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './notebox.scss';

const NoteBox = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noteTitle, setNoteTitle] = useState('');
  const [noteBody, setNoteBody] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await axios.get(`${backendURL}/notes/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": apiKey, // Add the API key to the headers
          }});
        const noteDetails = response.data.messageDetails[0];
        setNoteTitle(noteDetails.note_title);
        setNoteBody(noteDetails.note_body);
      } catch (error) {
        setError('Error fetching Note');
        console.error('Error fetching Note:', error);
      }
    };

    fetchMessage();
  }, [id]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-API-Key": apiKey
      };
  
      await axios.put(`${backendURL}/notes/${id}`, {
        note_title: noteTitle,
        note_body: noteBody
      }, { headers });
  
      setEditMode(false);
    } catch (error) {
      setError('Error saving Note');
      console.error('Error saving Note:', error);
    }
    setLoading(false);
  };

  const handleDeleteClick = async () => {
    setLoading(true);
    try {
      await axios.patch(`${backendURL}/notes/delete/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apiKey, // Add the API key to the headers
        }});
      navigate('/notes'); // Navigate to /notes on successful deletion
    } catch (error) {
      setError('Error deleting Note');
      console.error('Error deleting Note:', error);
    }
    setLoading(false);
  };

  if (error) {
    return <div className="note-box">{error}</div>;
  }

  return (
    <div className="note-box">
      <h1>Note Details</h1>
      {editMode ? (
        <div>
          <input
            type="text"
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
            placeholder="Note Title"
          />
          <div className="editor-container">
            <ReactQuill 
              value={noteBody} 
              onChange={setNoteBody} 
              style={{ height: '100%' }} 
            />
          </div>
          <div className="editor-buttons">
            <button onClick={handleSaveClick} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button onClick={() => setEditMode(false)}>Cancel</button>
          </div>
        </div>
      ) : (
        <div>
          <h2>{noteTitle}</h2>
          <div dangerouslySetInnerHTML={{ __html: noteBody }} />
          <button onClick={handleEditClick}>Edit</button>
        </div>
      )}
      <button onClick={handleDeleteClick} disabled={loading} className="delete-button">
        {loading ? 'Deleting...' : 'Delete'}
      </button>
    </div>
  );
};

export default NoteBox;