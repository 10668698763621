import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for the editor
import './messagenewform.scss'; // Import custom styles

const MessageNewForm = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const article = {
      title: title,
      content: content,
    };

    try {
      const response = await fetch('https://your-rest-server.com/api/articles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(article),
      });

      if (response.ok) {
        // Reset the form fields
        setTitle('');
        setContent('');
        alert('Article submitted successfully!');
      } else {
        alert('Failed to submit article');
      }
    } catch (error) {
      console.error('Error submitting article:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="article-form">
      <h1>New Message</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="to">To:</label>
          <input
            type="text"
            id="to"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="cc">cc:</label>
          <input
            type="text"
            id="cc"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="bcc">bcc:</label>
          <input
            type="text"
            id="bcc"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <ReactQuill
            value={content}
            onChange={setContent}
            theme="snow"
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, 
                 {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
              ],
            }}
            className="editor"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default MessageNewForm;
