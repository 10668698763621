import NoteNewForm from "../../components/notenewform/notenewform"
import "./notenew.scss"

const NoteNew = () => {

  //Fetch data and send to Single Component
  
  return (
    <div className="notenew">
      <NoteNewForm />
    </div>
  )
}

export default NoteNew