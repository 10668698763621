import React, { useState, useEffect } from "react";
import DataTable from "../../components/dataTable/DataTable";
import Add from "../../components/add/add";
import "./messages.scss";
import { backendURL, apiKey } from '../../config/config';

const columns = [
  { field: "sender_email", headerName: "Sender Email", width: 250 },
  { field: "subject", headerName: "Subject", width: 400 },
  { field: "formattedDate", headerName: "Date", width: 200 },
];

const Messages = () => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendURL}/messages`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": apiKey, // Add the API key to the headers
          }});
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const messagesWithUniqueId = data.messageDetails.map(message => ({
          ...message,
          id: message.message_id // Use message_id as the unique identifier
        }));
        setMessages(messagesWithUniqueId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="messages">
      <div className="info">
        <h1>Messages</h1>
        <button onClick={() => setOpen(true)}>Add New Message</button>
      </div>
      <DataTable
        slug="messages"
        columns={columns}
        rows={messages}
        uniqueIdentifier="id"
      />
      {open && <Add slug="message" setOpen={setOpen} />}
    </div>
  );
};

export default Messages;
