import MessageNewForm from "../../components/messagenewform/messagenewform"
import "./messagenew.scss"

const MessageNew = () => {

  //Fetch data and send to Single Component
  
  return (
    <div className="messagenew">
      <MessageNewForm />
    </div>
  )
}

export default MessageNew