import React, { useState, useEffect } from "react";
import DataTable from "../../components/dataTable/DataTable";
import Add from "../../components/add/add";
import "./notes.scss";
import { backendURL, apiKey } from '../../config/config';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const columns = [
  { field: "note_title", headerName: "Title", width: 400 },
  { field: "last_updated", headerName: "Updated", width: 150 },
];

const Notes = () => {
  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${backendURL}/notes`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": apiKey, // Add the API key to the headers
          }});
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const tasksWithUniqueId = data.taskDetails.map(notes => ({
          ...notes,
          id: notes.note_id // Use task_id as the unique identifier
        }));
        setTasks(tasksWithUniqueId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [open]); // Add `open` to the dependencies to refetch tasks when the modal is closed

  const handleAddNewNote = () => {
    navigate('/note-new'); // Navigate to /note-new
  };

  return (
    <div className="notes">
      <div className="info">
        <h1>Notes</h1>
        <button onClick={handleAddNewNote}>Add New Note</button>
      </div>
      <DataTable
        columns={columns}
        rows={tasks}
        slug="notes"
        backendUrl={`${backendURL}/notes`}
      />
      {open && (
        <Add
          slug="task"
          columns={columns}
          setOpen={setOpen}
          postUrl={`${backendURL}/notes`}
        />
      )}
    </div>
  );
};

export default Notes;
